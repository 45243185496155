$card-basic-hover-cursor: pointer;
$card-basic-icon-top-width: 2rem;

/*------------------------------------------------------------------
Cards - Table of Contents

1. Basic Cards
2. Degree Cards
3. Story Cards
4. Event Cards
5. Foldable Cards
6. Foldable - Desktop Disable
7. Accordion
8. Checkbox Stacked Cards
9. Checkbox Inline Cards
10. Checkbox Inline Cards
-------------------------------------------------------------------*/

.row-spaced {
  margin-bottom: -32px;
}

.row-spaced > .col,
.row-spaced > div {
  margin-bottom: 32px;
}

/*------------------------------------------------------------------
1. Basic Cards
--------------------------------------------------------------------*/

.card {
  height: 100%;
  display: flex;
  flex-direction: column;

  &.borderless {
    border-color: transparent;
  }
}

.card-hover:hover {
  transform: scale(1.05);
  cursor: $card-basic-hover-cursor;
  box-shadow: 0px 8px 16px rgba(25,25,25,0.2);
}

.card-hover:active {
  transform: scale(0.95);
  cursor: $card-basic-hover-cursor;
}

.card-hover:focus {
  outline: 0;
  box-shadow: 0 0 8px $uds-color-base-bluefocus !important;
}

.card-hover > button {
  border: none;
  background: transparent;
  text-align: inherit;
  margin: 0;
  padding: 0;
}

.card-hover > button:focus {
  outline: 0;
  box-shadow: 0 0 8px $uds-color-base-bluefocus !important;
  border: 0;
}

.card-icon-top {
  width: $card-basic-icon-top-width;
  height: $card-basic-icon-top-width;
  margin: 2rem 2rem 0 2rem;
}

.card-image-content {
  position: relative;
}

.card-image-gradient {
  max-width: 100%;
  height: 100%;
  width: 100%;
  margin: auto;
  position: relative;
}

.card-image-gradient::after {
  display: block;
  position: absolute;
  background: transparent linear-gradient(180deg, #19191900 0%, #191919c9 120%)
    0% 0% no-repeat padding-box;
  height: 100%;
  width: 100%;
  top: 0;
  content: '';
}

.card-img-top img,
.card-img-top {
  max-width: 100%;
  height: $card-image-top-height;
  object-fit: cover;
}

.card-header {
  padding: 24px 32px 16px 32px;
  flex-grow: 1;
  .card-icon {
    margin-bottom: $uds-size-spacing-2;
  }
}

.card-body, .accordion-body {
  padding: 0 32px 24px 32px;
  flex-grow: 100;
}

.card:not(.card.card-foldable) > div:first-of-type:not(.card-image-content) {
  flex-grow: 1;
}

.card-link {
  padding: 0 32px 24px 32px;
}

.card-footer {
  padding: 0 32px 24px 32px;
  border-top: 0;
}

.card-tags {
  padding: 0 32px 24px 32px;
  border-top: 0;
  margin-top: -8px;
  .btn-tag,
  .badge {
    margin-top: 8px;
    margin-left: 0;
    margin-right: 1rem;
  }
}

.card-button {
  margin-top: auto;
  padding: 0 32px 24px 32px;
  display: flex;
  flex-wrap: wrap;
  row-gap: 1rem;
  align-items: center;
}

.card > div:last-child {
  padding-bottom: 32px;
}

@include media-breakpoint-down(md) {
  .card-icon-top {
    width: $card-basic-icon-top-width;
    height: $card-basic-icon-top-width;
    margin: 2rem 2rem 0 2rem;
  }

  .card-img-top img,
  .card-img-top {
    height: 160px;
  }

  .card-header {
    padding: 24px 24px 16px 24px;
  }

  .card-body {
    padding: 0 24px 24px 24px;
  }

  .card-link {
    padding: 0 24px 24px 24px;
  }

  .card-footer {
    padding: 0 24px 24px 24px;
    border-top: 0;
  }

  .card-tags {
    padding: 0 24px 24px 24px;
    border-top: 0;
  }

  .card-button {
    margin-top: auto;
    padding: 0 24px 24px 24px;
  }

  @include media-breakpoint-down(md) {
    .card-buttons {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      .card-button {
        display: inline;
        padding-right: 0;
      }
    }
  }

  // TODO: This block of rules causes inconsistency between buttons side
  // TODO: removed ad per request on ticket UDS-866
  // TODO: we should consider to review all CSS, for any button types, in small viewport
  // .card-button .btn {
  //   font-size: 0.75rem;
  //   padding: $uds-component-button-padding-y-small
  //     $uds-component-button-padding-x-small;
  //   line-height: 1rem;
  // }

  .card > div:last-child {
    padding-bottom: 24px;
  }
}

@include media-breakpoint-up(lg) {
  .col-lg-6 .card {
    .card-img-top img,
    .card-img-top {
      height: 240px;
    }
  }

  .col-lg-6 .card-horizontal .card-img-top {
    height: auto;
    max-width: 40%;
  }
}

.card-sm {
  .card-body {
    padding-right: 24px;
    padding-left: 24px;
  }

  .card-header {
    padding: 24px 24px 16px 24px;
  }
  .card > div:first-of-type {
    padding-top: 24px;
    flex-grow: 1;
  }

  .card-image-gradient::after {
    height: 160px;
  }

  .card-img-top img,
  .card-img-top {
    height: 160px;
  }

  .card-footer-link {
    padding-right: 24px;
    padding-left: 24px;
  }

  .card-button {
    padding-right: 24px;
    padding-left: 24px;
  }

  .card-link {
    padding-right: 24px;
    padding-left: 24px;
  }

  .card-tags {
    padding-right: 24px;
    padding-left: 24px;
  }

  .card-event-details {
    padding-right: 24px;
    padding-left: 24px;
  }
}

.card-lg {
  .card-img-top img,
  .card-img-top {
    height: 15rem;
  }
  .card-image-gradient::after {
    height: 15rem;
  }
}

.card-header .card-title {
  margin: 0;
}

.card-title a,
.card-title a:visited {
  color: $uds-color-base-gray-7;
  text-decoration: none;
}

.card-title a:hover {
  text-decoration: underline;
}

/*------------------------------------------------------------------
2. Degree Cards
--------------------------------------------------------------------*/

.card-degree .card-header .card-title:after {
  content: '';
  width: 2rem;
  height: 0.25rem;
  display: block;
  background-color: $uds-color-base-gold;
  margin-top: 1rem;
}

.card-degree .card-footer {
  background-color: $white;
}

.card-footer-link a,
.card-footer-link a:visited {
  color: $uds-color-base-gray-7;
  text-decoration: none;
  font-weight: bold;
  display: block;
}

.card-footer-link a:hover {
  text-decoration: underline;
}

.card-footer-link a:after {
  float: right;
  height: 20px;
  width: 20px;
  content: url("data:image/svg+xml; utf8, <svg aria-hidden='true' focusable='false' data-prefix='fas' data-icon='arrow-right' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512' class='svg-inline--fa fa-arrow-right fa-w-14 fa-2x'><path fill='currentColor' d='M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z'></path></svg>");
}

/*------------------------------------------------------------------
3. Story Cards
--------------------------------------------------------------------*/

.card-story .card-header,
.card-story .card-body,
.card-story .card-button,
.card-story .card-footer,
.card-story .card-tags,
.card-story .card-footer,
.card-story .card-link {
  margin-left: 24px;
  margin-right: 24px;
  background-color: $white;
  padding-left: 16px;
  padding-right: 16px;
}

.card-story:not(.card.card-foldable)
  > div:first-of-type:not(.card-image-content, .uds-img) {
  padding-top: 16px;
}

.card-story > div:first-of-type:not(.card-image-content, .uds-img) {
  padding: 16px;
  flex-grow: 1;
}

.card-story .card-footer {
  padding: 1rem 2rem 1rem 2rem;
  padding-left: 24px;
  padding-right: 24px;
}

.card-story .card-img-top {
  margin-bottom: -48px;
}

.card-story.card-sm {
  .card-body {
    margin: 0 1rem 0 1rem;
  }

  .card-header {
    margin: 0 1rem 0 1rem;
  }

  .card-button {
    margin: 0 1rem 0 1rem;
  }
}

.card-story.card-horizontal .card-img-top {
  margin-right: -32px;
  position: relative;
  z-index: 0;
}

.card-story.card-horizontal .card-content-wrapper {
  margin-top: 16px;
  margin-bottom: 16px;
  position: relative;
  z-index: 100;
  background-color: white;
}

.card-story.card-horizontal {
  .card-header,
  .card-body,
  .card-button,
  .card-footer,
  .card-tags,
  .card-footer,
  .card-link {
    margin-left: 0;
    margin-right: 0;
    background-color: $white;
    padding-left: 24px;
    padding-right: 24px;
  }
}

/*------------------------------------------------------------------
4. Event Cards
--------------------------------------------------------------------*/
.card-event .card-header .card-title {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.card-event .card-header {
  border-top: solid 8px $gold;
}

.card-event-details {
  display: flex;
  flex-grow: 100000;
  padding: 0 2rem 2rem 2rem;
  padding: 0 32px 24px 32px;
  font-size: 14px;
  > * {
    flex: 50%;
  }
}
.card-event-details + .card-event-details {
  flex-grow: 999900000;
}

.card-event-details > div:first-child {
  margin-right: 16px;
}

.card-event-icons {
  display: flex;
}

.card-event-icons > div:first-child {
  width: 16px;
  margin-right: 8px;
}

.card-horizontal {
  flex-direction: row;
}

@include media-breakpoint-down(md) {
  .card-horizontal {
    flex-direction: column;
  }

  .card-horizontal .card-img-top {
    height: 160px !important;
    max-width: 100% !important;
    width: 100% !important;
  }

  .card-event.card-horizontal .card-content-wrapper {
    border-top: solid 8px $uds-color-base-gold;
    border-left: 0 !important;
  }

  .card-story.card-horizontal .card-content-wrapper {
    margin-right: 16px;
    margin-left: 16px;
    margin-top: -24px;
    margin-bottom: 0;
  }
}

.card-content-wrapper {
  padding-bottom: 0 !important;
}

.card-story.card-horizontal .card-img-top {
  margin-bottom: 0;
}

.card-horizontal .card-img-top {
  height: auto;
  max-width: 40%;
  aspect-ratio: 2/3;
  max-height: 400px;
}

.card-event.card-horizontal .card-header {
  border-top: 0;
}

.card-event.card-horizontal .card-content-wrapper {
  border-left: solid 8px $gold;
}

/*------------------------------------------------------------------
5. Foldable Cards
--------------------------------------------------------------------*/
@mixin btn-transparent {
  border-color: transparent;
  background-color: transparent;
}

.accordion-item  {
  border-color: $uds-color-base-gray-3;
  border-left: $uds-size-spacing-1 solid $uds-color-base-gold;
  height: auto;

  &.accordion-item-maroon {
    border-left-color: $uds-color-base-maroon;
  }

  &.accordion-item-gray {
    border-left-color: $uds-color-base-gray-4;
  }

  &.accordion-item-dark {
    border-left-color: $uds-color-base-gray-7;
  }

  .accordion-header {
    padding: $uds-size-spacing-1;
    overflow: hidden;
    background-color: $uds-color-base-white;

    &.accordion-header-icon {
      & .accordion-icon {
        display: flex;
        margin-left: calc(#{$uds-size-spacing-3} / -2);
        align-items: flex-start;

        svg {
          margin-top: 2px; //adjust for line height of text
        }
      }
    }

    &:hover {
      background-color: $uds-color-base-gray-2;
    }

    h3 {
      font-size: 1.25rem;
    }

    h3,
    h4,
    h5 {
      margin: 0;

      a {
        padding: $uds-size-spacing-1 $uds-size-spacing-3;
        color: $uds-color-base-gray-7;
        text-decoration: none;
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: center;

        svg.fa-chevron-up {
          margin-left: $uds-size-spacing-3;
          transition-duration: $uds-time-transition-base;
          align-self: flex-start;
        }

        &.collapsed svg.fa-chevron-up,
        &.collapsed i.fa-chevron-up {
          transform: rotate(180deg);
        }
      }
    }

    + .accordion-body {
      border-top: 1px solid $uds-color-base-gray-3;
    }
  }

  .accordion-body {
    background-color: $uds-color-base-gray-1;

    > p:first-child {
      margin-top: $uds-size-spacing-2;
    }

    > p:last-child {
      margin-bottom: 0;
    }
  }
}

/*------------------------------------------------------------------
6. Foldable - Desktop Disable
--------------------------------------------------------------------*/
@mixin desktop-disable {
  border-left: 1px solid $uds-color-base-gray-3;

  .accordion-header {
    h4 a {
      padding-top: $uds-size-spacing-4;
      padding-bottom: $uds-size-spacing-4;

      &:hover {
        background-color: transparent;
      }
    }

    svg {
      display: none;
    }

    span {
      display: none;
    }
  }

  .accordion-header + .accordion-body {
    border-top: 0;
  }

  .accordion-body {
    background-color: transparent;

    > p:first-child {
      margin-top: 0;
    }
  }

  .collapse {
    display: block;
    height: auto !important;
    visibility: visible;

    &:not(.show) {
      display: block;
    }
  }

  .collapsing {
    position: relative;
    height: unset !important;
    overflow: hidden;
  }
}

@include media-breakpoint-up(xl) {
  .accordion-item.desktop-disable-xl {
    @include desktop-disable;
  }
}

@include media-breakpoint-up(lg) {
  .accordion-item.desktop-disable-lg {
    @include desktop-disable;
  }
}

@include media-breakpoint-up(md) {
  .accordion-item.desktop-disable-md {
    @include desktop-disable;
  }
}

/*------------------------------------------------------------------
7. Accordion
--------------------------------------------------------------------*/
.accordion {
  .accordion-item {
    border-bottom: 1px solid $uds-color-base-gray-3;
    border-top: 1px solid $uds-color-base-gray-3;
  }
  .accordion-header .accordion-icon {
    display: flex;
    align-items: center;
    margin-bottom: 0;
  }
}

/*------------------------------------------------------------------
8. Checkbox Stacked Cards
--------------------------------------------------------------------*/

.card-checkbox-stacked .card-header {
  position: relative;
}

.card-checkbox-stacked .card-footer {
  background-color: $white;
}

/*------------------------------------------------------------------
9. Checkbox Inline Cards
--------------------------------------------------------------------*/

.card-checkbox-inline .card-header {
  position: relative;
}

.card-checkbox-inline .card-footer {
  background-color: $white;
}

/*------------------------------------------------------------------
10. Checkbox Inline Cards
--------------------------------------------------------------------*/
.media-type-overlay {
  background-color: white;
  border: 1px solid $uds-color-base-gray-3;
  border-radius: 32px;
  width: 64px;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/*------------------------------------------------------------------
11. Generic Header Image Overlay
--------------------------------------------------------------------*/
.card-image-overlay-content {
  position: absolute;
  bottom: 0;
  color: $white;

  .fa-video {
    color: $uds-color-base-gray-7;
    font-size: 2rem;
  }
}

.icon-example {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
